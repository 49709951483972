import "./App.scss";
import {BrowserRouter, Link, Redirect, Switch} from "react-router-dom";
import {Route, useHistory} from "react-router";
import Authentication from "./components/authentication/Authentication";
import AppNavbar from "./components/global/navbar/AppNavbar";
import {getAccount, logoutLocally} from "./components/authentication/Oauth2Service";
import React, {useEffect, useState} from "react";
import useToken from "./components/authentication/TokenStorageService";
import GlobalConstants from "./components/config/GlobalConstants";
import Dashboard from "./components/resources/dashboard/Dashboard";
import Tigerboxes from "./components/resources/tigerboxes/Tigerboxes";
import Subscriptions from "./components/resources/subscriptions/Subscriptions";
import ProtectedRoute from "./components/util/ProtectedRoute";
import {authCheck} from "./components/util/authCheck";
import Tigertickets from "./components/resources/tigertickets/Tigertickets";
import Wildcards from "./components/resources/wildcards/Wildcards";
import ForgotPassword from "./components/authentication/ForgotPassword";
import Audiobooks from "./components/resources/audiobooks/Audiobooks";
import WildcardDetails from "./components/resources/wildcards/wildcard-details-page/WildcardDetails";
import logo from "./assets/images/loader_bg.png";
import Strings from "./components/config/Strings";
import ResetPassword from "./components/authentication/ResetPassword";
import RegisterForm from "./components/authentication/RegisterForm";
import ProductDetails from "./components/resources/products/ProductDetails";
import SeriesDetails from "./components/resources/series/SeriesDetails";
import AllSeries from "./components/resources/series/AllSeries";
import useShopToken from "./components/authentication/ShopTokenStorageService";
import ChangePassword from "./components/authentication/ChangePassword";
import Invoices from "./components/resources/premium/invoices/Invoices";
import ContractCancellation from "./components/resources/forms/cancellation/ContractCancellation";
import NewsletterLandingPage from "./components/resources/landingpages/NewsletterLandingPage";
import Header from "./components/global/header/Header";
import Footer from "./components/global/footer/Footer";
import RouteWithNavbar from "./components/util/RouteWithNavbar";
import TigerpostB2B from "./components/resources/forms/tigerpostB2B/TigerpostB2B";
import UnsubscribeTigerpostB2B from "./components/resources/forms/tigerpostB2B/UnsubscribeTigerpostB2B";
import PlaylistDetails from "./components/resources/playlists/PlaylistDetails";
import DeeplinkRedirect from "./components/resources/forms/redirect/DeeplinkRedirect";
import DDFRedemptionTicketValidation from "./components/resources/forms/ddf/DDFRedemptionTicketValidation";
import DDFRedemptionAuthentication from "./components/resources/forms/ddf/DDFRedemptionAuthentication";
import DDFRedemptionPreview from "./components/resources/forms/ddf/DDFRedemptionPreview";
import DDFRedemptionSuccess from "./components/resources/forms/ddf/DDFRedemptionSuccess";
import DdfDeeplinkRedirect from "./components/resources/forms/ddf/DdfDeeplinkRedirect";
import TicketRedemptionValidation from "./components/resources/forms/redemption/TicketRedemptionValidation";
import TicketRedemptionAuthentication from "./components/resources/forms/redemption/TicketRedemptionAuthentication";
import TicketRedemptionPreview from "./components/resources/forms/redemption/TicketRedemptionPreview";
import TicketRedemptionSuccess from "./components/resources/forms/redemption/TicketRedemptionSuccess";
import PurchaseRedirect from "./components/resources/forms/purchase/PurchaseRedirect";
import PurchaseError from "./components/resources/forms/purchase/PurchaseError";
import TicketPurchaseAuthentication
    from "./components/resources/forms/purchase/digiticket/TicketPurchaseAuthentication";
import TicketPurchasePayment from "./components/resources/forms/purchase/digiticket/TicketPurchasePayment";
import TicketPurchaseAddress from "./components/resources/forms/purchase/digiticket/TicketPurchaseAddress";
import TicketPurchaseConfirmation from "./components/resources/forms/purchase/digiticket/TicketPurchaseConfirmation";
import TicketPurchaseSuccess from "./components/resources/forms/purchase/digiticket/TicketPurchaseSuccess";
import SubscriptionPurchaseAuthentication
    from "./components/resources/forms/purchase/subscription/SubscriptionPurchaseAuthentication";
import SubscriptionPurchasePayment
    from "./components/resources/forms/purchase/subscription/SubscriptionPurchasePayment";
import SubscriptionPurchaseAddress
    from "./components/resources/forms/purchase/subscription/SubscriptionPurchaseAddress";
import SubscriptionPurchaseConfirmation
    from "./components/resources/forms/purchase/subscription/SubscriptionPurchaseConfirmation";
import SubscriptionPurchaseSuccess
    from "./components/resources/forms/purchase/subscription/SubscriptionPurchaseSuccess";
import HuibuhRedemptionTicketValidation from "./components/resources/forms/huibuh/HuibuhRedemptionTicketValidation";
import HuibuhRedemptionAuthentication from "./components/resources/forms/huibuh/HuibuhRedemptionAuthentication";
import HuibuhRedemptionPreview from "./components/resources/forms/huibuh/HuibuhRedemptionPreview";
import HuibuhRedemptionSuccess from "./components/resources/forms/huibuh/HuibuhRedemptionSuccess";
import HuibuhDeeplinkRedirect from "./components/resources/forms/huibuh/HuibuhDeeplinkRedirect";

import DdfFilmRedemptionTicketValidation from "./components/resources/forms/ddf-film/DdfFilmRedemptionTicketValidation";
import DdfFilmRedemptionAuthentication from "./components/resources/forms/ddf-film/DdfFilmRedemptionAuthentication";
import DdfFilmRedemptionPreview from "./components/resources/forms/ddf-film/DdfFilmRedemptionPreview";
import DdfFilmRedemptionSuccess from "./components/resources/forms/ddf-film/DdfFilmRedemptionSuccess";
import DdfFilmDeeplinkRedirect from "./components/resources/forms/ddf-film/DdfFilmDeeplinkRedirect";
import ReweRegisterForm from "./components/resources/forms/rewe/RegisterForm";

import Profiles from "./components/resources/profiles/Profiles";
import ProfileDetails from "./components/resources/profiles/profile-details-page/ProfileDetails";
import ProfileWatchlist from "./components/resources/watchlist/ProfileWatchlist";
import ChangeEmail from "./components/authentication/ChangeEmail";
import AccountDeletionLandingPage from "./components/resources/landingpages/AccountDeletionLandingPage";
import ClusterDetails from "./components/resources/clusters/ClusterDetails";
import Premium from "./components/resources/premium/Premium";
import PremiumSettings from "./components/resources/premium/settings/PremiumSettings";
import TigerboxDetails from "./components/resources/tigerboxes/tigerboxes-details-page/TigerboxDetails";
import TarifAuthentication from "./components/resources/forms/purchase/tarif/TarifAuthentication";
import TarifPayment from "./components/resources/forms/purchase/tarif/TarifPayment";
import TarifAddress from "./components/resources/forms/purchase/tarif/TarifAddress";
import TarifConfirmation from "./components/resources/forms/purchase/tarif/TarifConfirmation";
import TarifSuccess from "./components/resources/forms/purchase/tarif/TarifSuccess";
import TarifProductSelection from "./components/resources/forms/purchase/tarif/TarifProductSelection";
import AccountActivationLandingPage from "./components/resources/landingpages/AccountActivationLandingPage";
import EmailChangedLandingPage from "./components/resources/landingpages/EmailChangedLandingPage";

//search
import SearchResult from "./components/resources/search/SearchResult";

export default function App() {
  //Authentication
  const { token, setToken } = useToken();
  const { setShopToken} = useShopToken();
  const [roles, setRoles] = useState([]);
  const [accountId, setAccountId] = useState(null);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (authCheck(token) && accountId == null) {

        getAccount(token).then((r) => {
            if(r !== undefined) {
                setAccountId(r.id);
                setEmail(r.email);
                setError(false);

                sessionStorage.setItem('email', JSON.stringify({email: r.email}));

            } else {
                setError(true);
            }
        });
    }
  }, [token, roles, setShopToken, accountId]);

  //Check if the roles were able to be loaded if a token exists (= Backend available?)
  if(error) {
    return(
        <>
            <Header />
            <BrowserRouter>
          <AppNavbar setToken={setToken} roles={roles} setRoles={setRoles} setAccountId={setAccountId} />
          <div style={{width: "100vw", height: "100vw", textAlign: "center", fontSize: "25px"}}>
              <br />
              <img src={logo} alt="tigermedia logo" width={100} />
              <br />
              <br />
              <span style={{fontSize: "40px"}}>{Strings.GLOBAL_ERROR_TITLE}</span>
              <br />
              <br />
              {Strings.GLOBAL_ERROR_MESSAGE} <Link to={"/" + GlobalConstants.APP_PATH + "login"} onClick={() => {
                  logoutLocally({setToken, setRoles, setAccountId});
                  setError(false);
          }}>{Strings.GLOBAL_ERROR_LINK}</Link>.
          </div>
            </BrowserRouter>
            <Footer />
        </>
    )
  }

  return (
    <>
      {/* Routing */}
      <BrowserRouter>

        <Switch>
            {/* Authentication etc. */}
          <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "login"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            component={() => <Authentication setToken={setToken}/>}
          />

          <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "forgot-password"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            component={() => <ForgotPassword setToken={setToken} />}
          />

          <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "reset-password"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            component={() => <ResetPassword setToken={setToken} />}
          />

          <RouteWithNavbar
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "register"}
              setToken={setToken}
              roles={roles}
              setRoles={setRoles}
              setAccountId={setAccountId}
              component={() => <RegisterForm setToken={setToken} />}
          />

          <RouteWithNavbar
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "product/:id(-?\\d+)"}
              accountId={accountId}
              setToken={setToken}
              roles={roles}
              history={history}
              setRoles={setRoles}
              setAccountId={setAccountId}
              component={ProductDetails}
          />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "product/:slug(.*)/:id(-?\\d+)"}
                    accountId={accountId}
                    setToken={setToken}
                    roles={roles}
                    history={history}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    component={ProductDetails}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "series/all"}
                    history={history}
                    component={AllSeries}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "series/:id(-?\\d+)"}
                    accountId={accountId}
                    history={history}
                    component={SeriesDetails}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "series/:slug(.*)/:id(-?\\d+)"}
                    accountId={accountId}
                    history={history}
                    component={SeriesDetails}
            />

            <RouteWithNavbar
                exact={true}
                path={"/" + GlobalConstants.APP_PATH + "cluster/:id(-?\\d+)"}
                setToken={setToken}
                history={history}
                setAccountId={setAccountId}
                component={ClusterDetails}
            />

          <RouteWithNavbar
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "kuendigen"}
              setToken={setToken}
              roles={roles}
              setRoles={setRoles}
              setAccountId={setAccountId}
              component={ContractCancellation}
          />

          <RouteWithNavbar
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "activate"}
              history={history}
              component={AccountActivationLandingPage}
              />

          <RouteWithNavbar
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "email-changed"}
              history={history}
              component={EmailChangedLandingPage}
              />

          <Route
              exact={true}
              path={"/" + GlobalConstants.APP_PATH + "weiterleitung"}
              component={DeeplinkRedirect}
          />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "newsletter"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            component={() => <NewsletterLandingPage email={email} />}
        />

        {/* Redemption */}
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ticket"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            history={history}
            accountId={accountId}
            setAccountId={setAccountId}
            component={() => <TicketRedemptionValidation accountId={accountId} history={history} />}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ticket/authenticate"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <TicketRedemptionAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ticket/preview"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <TicketRedemptionPreview email={email} setToken={setToken} history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ticket/success"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <TicketRedemptionSuccess email={email} token={token} setToken={setToken} history={history}/>}
        />

        {/* PURCHASES */}
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/error"}
            component={() => <PurchaseError />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket/authenticate"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <TicketPurchaseAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket/payment"}
            component={() => <TicketPurchasePayment />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket/address"}
            component={() => <TicketPurchaseAddress />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket/confirmation"}
            component={() => <TicketPurchaseConfirmation />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket/success"}
            component={() => <TicketPurchaseSuccess />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/ticket"}
        >
            <Redirect to={"/" + GlobalConstants.APP_PATH + "purchase/ticket/authenticate"}/>
        </RouteWithNavbar>

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <TarifAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif/select"}
            component={() => <TarifProductSelection />}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif/payment"}
            component={() => <TarifPayment />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif/address"}
            component={() => <TarifAddress />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif/confirmation"}
            component={() => <TarifConfirmation />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/tarif/success"}
            component={() => <TarifSuccess />}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription/authenticate"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <SubscriptionPurchaseAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription/payment"}
            component={() => <SubscriptionPurchasePayment />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription/address"}
            component={() => <SubscriptionPurchaseAddress />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription/confirmation"}
            component={() => <SubscriptionPurchaseConfirmation />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription/success"}
            component={() => <SubscriptionPurchaseSuccess />}
        />
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/subscription"}
        >
            <Redirect to={"/" + GlobalConstants.APP_PATH + "purchase/subscription/authenticate"}/>
        </RouteWithNavbar>

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/:planVariantId"}
            component={() => <PurchaseRedirect />}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "purchase/"}
            component={() => <PurchaseRedirect />}
        />

        {/* DDF */}
        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ddflive"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            headerBackgroundColor={"#000000"}
            history={history}
            footerBackgroundColor={"#010202"}
            footerColor={"#010202"}
            hideHeader={true}
            component={() => <DDFRedemptionTicketValidation history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ddflive/authenticate"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            headerBackgroundColor={"#000000"}
            history={history}
            footerBackgroundColor={"#010202"}
            footerColor={"#010202"}
            hideHeader={true}
            component={() => <DDFRedemptionAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ddflive/preview"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            headerBackgroundColor={"#000000"}
            history={history}
            footerBackgroundColor={"#010202"}
            footerColor={"#010202"}
            hideHeader={true}
            component={() => <DDFRedemptionPreview email={email} setToken={setToken} history={history}/>}
        />

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ddflive/success"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            headerBackgroundColor={"#000000"}
            history={history}
            footerBackgroundColor={"#010202"}
            footerColor={"#010202"}
            hideHeader={true}
            component={() => <DDFRedemptionSuccess email={email} token={token} setToken={setToken} history={history}/>}
        />



        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "ddflive/playlist"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            history={history}
            component={() => <PlaylistDetails />}
        />

        <Route
                exact={true}
                path={"/" + GlobalConstants.APP_PATH + "ddf-redirect"}
                component={DdfDeeplinkRedirect}
        />

            {/* Huibuh */}
            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <HuibuhRedemptionTicketValidation history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh/authenticate"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <HuibuhRedemptionAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh/preview"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <HuibuhRedemptionPreview email={email} setToken={setToken} history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh/success"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <HuibuhRedemptionSuccess email={email} token={token} setToken={setToken} history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh/playlist"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    history={history}
                    component={() => <PlaylistDetails />}
            />

            <Route
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "huibuh-redirect"}
                    component={HuibuhDeeplinkRedirect}
            />

            {/* DDF Film */}
            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <DdfFilmRedemptionTicketValidation history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/authenticate"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <DdfFilmRedemptionAuthentication setToken={setToken} setEmail={setEmail} history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/preview"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <DdfFilmRedemptionPreview email={email} setToken={setToken} history={history}/>}
            />

            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/success"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    headerBackgroundColor={"#000000"}
                    history={history}
                    footerBackgroundColor={"#010202"}
                    footerColor={"#010202"}
                    hideHeader={true}
                    component={() => <DdfFilmRedemptionSuccess email={email} token={token} setToken={setToken} history={history}/>}
            />



            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "dreifragezeichenfilm/playlist"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    setAccountId={setAccountId}
                    history={history}
                    component={() => <PlaylistDetails />}
            />

            <Route
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "ddf-film-redirect"}
                    component={DdfFilmDeeplinkRedirect}
            />

            {/* Rewe 2024 */}
            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "rewe/registration"}
                    setToken={setToken}
                    component={() => <ReweRegisterForm setToken={setToken} />}
            />

            {/* HAENDLER */}

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "haendler"}
            ><Redirect to={"/" + GlobalConstants.APP_PATH + "haendler/tigerpost"}/></RouteWithNavbar>

        <RouteWithNavbar
            exact={true}
            path={"/" + GlobalConstants.APP_PATH + "haendler/tigerpost"}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            component={() => <TigerpostB2B />}
        />

            <RouteWithNavbar
                exact={true}
                path={"/" + GlobalConstants.APP_PATH + "haendler/tigerpost-abmeldung"}
                setToken={setToken}
                roles={roles}
                setRoles={setRoles}
                setAccountId={setAccountId}
                component={() => <UnsubscribeTigerpostB2B />}
            />

            {/* Search */}
            <RouteWithNavbar
                    exact={true}
                    path={"/" + GlobalConstants.APP_PATH + "search"}
                    setToken={setToken}
                    roles={roles}
                    setRoles={setRoles}
                    history={history}
                    accountId={accountId}
                    setAccountId={setAccountId}
                    component={() => <SearchResult accountId={accountId} history={history} />}
            />


          {/* Main */}
          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "dashboard"}
            component={Dashboard}
            accountId={accountId}
            email={email}
            setToken={setToken}
            history={history}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
            setEmail={setEmail}
          />

          {/* Main */}
          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "change-password"}
            component={ChangePassword}
            accountId={accountId}
            email={email}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

        <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "change-email"}
            component={ChangeEmail}
            accountId={accountId}
            email={email}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
        />

        <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "delete-account"}
            component={AccountDeletionLandingPage}
            accountId={accountId}
            email={email}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
        />

          {/* Resources */}
          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "audiobooks"}
            component={Audiobooks}
            accountId={accountId}
            setToken={setToken}
            history={history}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

            <ProtectedRoute
                exact
                path={"/" + GlobalConstants.APP_PATH + "tigerbox-details"}
                component={TigerboxDetails}
                accountId={accountId}
                history={history}
                setToken={setToken}
                roles={roles}
                setRoles={setRoles}
                setAccountId={setAccountId}
            />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "tigerboxes"}
            component={Tigerboxes}
            accountId={accountId}
            setToken={setToken}
            history={history}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "tigertickets"}
            component={Tigertickets}
            accountId={accountId}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "wildcards"}
            component={Wildcards}
            accountId={accountId}
            history={history}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "wildcard-details"}
            component={WildcardDetails}
            accountId={accountId}
            history={history}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

        <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "profiles"}
            component={Profiles}
            accountId={accountId}
            history={history}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
        />

        <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "profile-details"}
            component={ProfileDetails}
            accountId={accountId}
            history={history}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
        />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "subscriptions"}
            component={Subscriptions}
            accountId={accountId}
            setToken={setToken}
            roles={roles}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

          <ProtectedRoute
            exact
            path={"/" + GlobalConstants.APP_PATH + "watchlist"}
            component={ProfileWatchlist}
            accountId={accountId}
            setToken={setToken}
            roles={roles}
            history={history}
            setRoles={setRoles}
            setAccountId={setAccountId}
          />

            <ProtectedRoute
                exact
                path={"/" + GlobalConstants.APP_PATH + "premium/settings"}
                component={PremiumSettings}
                accountId={accountId}
                setToken={setToken}
                roles={roles}
                history={history}
                setRoles={setRoles}
                setAccountId={setAccountId}
            />

            <ProtectedRoute
                exact
                path={"/" + GlobalConstants.APP_PATH + "premium"}
                component={Premium}
                accountId={accountId}
                setToken={setToken}
                roles={roles}
                history={history}
                setRoles={setRoles}
                setAccountId={setAccountId}
            />

          <ProtectedRoute
              exact
              path={"/" + GlobalConstants.APP_PATH + "invoices"}
              component={Invoices}
              accountId={accountId}
              setToken={setToken}
              roles={roles}
              setRoles={setRoles}
              setAccountId={setAccountId}
          />

          {authCheck(token) ? (
            <Redirect to={"/" + GlobalConstants.APP_PATH + "dashboard"} />
          ) : (
            <Redirect to={"/" + GlobalConstants.APP_PATH + "login"} />
          )}
        </Switch>
      </BrowserRouter>
    </>
  );
}
import React, {useEffect, useState} from "react";
import './TicketRedemption.css';
import Strings from "../../../config/Strings";
import {Form, Spinner} from "react-bootstrap";
import {TigerButton} from "../../../global/tiger-button/TigerButton";
import {GetFilteredResources} from "../../../api/SpineClient";
import GlobalConstants from "../../../config/GlobalConstants";
import {InfoModal} from "../../../global/ConfirmationModal";
import {useHistory} from "react-router";
import {ConvertDateToReadableString} from "../../../util/ConverterUtil";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

export default function TicketRedemptionValidation({accountId}) {

    const queryParams = new URLSearchParams(window.location.search);

    const [form, setForm] = useState({code: queryParams.get("code") ? queryParams.get("code") : '',
        pin: queryParams.get("pin") ? queryParams.get("pin") : ''});
    const [formError, setFormError] = useState({code: null, pin: null});
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const history = useHistory();

    document.title = Strings.TICKET_REDEMPTION_REDEMPTION_BROWSER_TAB;
    window.scrollTo(0, 0);

    useEffect(() => {

        if (queryParams.get("code") && queryParams.get("code")) {
            processValidation();
        }
    // eslint-disable-next-line
    }, []);

    //Submitting
    const validateTicket = async e => {
        e.preventDefault();
        processValidation();
    }

    const processValidation = async () => {
        setLoading(true);

        let validation = await GetFilteredResources('api/purchases/tickets/validate', form, 'application/json', GlobalConstants.BILL_HOST);

        setLoading(false);

        if (validation.error) {
            setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
            setShowError(true);
        } else {
            await handleValidationResult(validation.result);
        }
    }

    const handleValidationResult = async (result) => {

        let valid = result.valid;
        const code = result.code;
        let unknownCode = false;

        //Validate the response from the purchase service
        switch (code) {
            case 0:
                break;
            case 1:
                //Coupon does not exist
                setFormError({
                    ...formError,
                    code:  Strings.TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_1
                });
                break;
            case 2:
                //Coupon temporary disabled for X Minutes
                setFormError({
                    ...formError,
                    code: Strings.TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_2.replace("{0}", result.value)
                });
                break;
            case 3:
                //Wrong PIN provided for last time -> Coupon is now disabled
                setFormError({
                    ...formError,
                    code: Strings.TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_3
                });
                break;
            case 4:
                //Wrong PIN provided -> X retries left
                setFormError({
                    ...formError,
                    pin: Strings.TICKET_REDEMPTION_REDEMPTION_PIN_ERROR_1.replace("{0}", result.value)
                });
                break;
            case 5:
                //Already used
                setFormError({
                    ...formError,
                    code: Strings.TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_4
                });
                break;
            case 6:
                setFormError({
                    ...formError,
                    code: Strings.TICKET_REDEMPTION_REDEMPTION_CODE_ERROR_6
                });
                break;
            case 7:
                //Active subscription (unknown cancelltion status)
                setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_1);
                setShowError(true);
                break;
            case 8:
                //Active subscription (not cancelled)
                setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_2.replace("{0}", ConvertDateToReadableString(result.value)));
                setShowError(true);
                break;
            case 9:
                //Active subscription (cancelled)
                setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_ACCOUNT_ERROR_3.replace("{0}", ConvertDateToReadableString(result.value)));
                setShowError(true);
                break;
            default:
                //Unknown code
                unknownCode = true
                break;
        }

        //In case of an already used ticket, redirect to ddf playlist (depending on client)
        if (valid) {
            //It's not used -> It can be redeemed (proceed to next step)
            sessionStorage.setItem('ls.ticket', JSON.stringify(form));

            //Load the product
            await loadProduct();
        } else if (unknownCode) {
            setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
            setShowError(true);
        }
    }

    const loadProduct = async() => {
        setLoading(true);

        let productRes = await GetFilteredResources('api/purchases/tickets/preview', form, 'application/json', GlobalConstants.BILL_HOST);

        setLoading(false);

        if (productRes.error || productRes.result == null) {
            setErrorText(Strings.TICKET_REDEMPTION_REDEMPTION_UNKNOWN_ERROR);
            setShowError(true);
        } else {
            form.product = productRes.result.linkedProduct != null ? {
                    description: productRes.result.linkedProduct.planVariantDescription,
                    period: productRes.result.linkedProduct.period,
                    price: productRes.result.linkedProduct.price
                }
                :
                {
                    description: null,
                    period: {unit: "month", quantity: 1},
                    price: 0.0
                };
            form.endDate = productRes.result.newEndDate;
            sessionStorage.setItem('ls.ticket', JSON.stringify(form));
            history.push("/" + GlobalConstants.APP_PATH + "ticket/authenticate")
        }
    }

    const updateForm = (field, value) => {
        setForm({
            ...form,
            [field]: value
        });
        setFormError({
            ...formError,
            [field]: null
        });
    }

    //Rendering
    return (
        <div className="ticket-redemption-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{"ticket für tigertones einlösen :: tiger.media"}</title>
                <meta name="description" content={'Löse hier dein Ticket ein und erhalte Zugriff auf Zehntausende Hörspiele, Hörbücher und jede Menge Musik in tigertones und auf deiner tigerbox'} />
                <meta name="keywords" content={'tigertones,freischalten,einlösen,tigerbox,zugang,tigermedia,prepaid'} />
                <link rel="canonical" href={"https://my.tiger.media/ticket"}/>
            </Helmet>


            <div className="ticket-redemption-title"><h1 dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_VALIDATION_TITLE}} /></div>
            <div className="ticket-redemption-info" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: Strings.TICKET_REDEMPTION_REDEMPTION_INFO}} />

            <div className="ticket-redemption-form-container form-container-small">
                <div className="ticket-redemption-form">
                    <Form className="text-left" onSubmit={(e) => validateTicket(e)}>
                        {/* CODE */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketNo">
                            <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_TICKET_NO}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.code != null}
                                value={form.code}
                                onChange={e => updateForm('code', e.target.value)}
                                plaintext={"Code"}
                                placeholder={Strings.TICKET_REDEMPTION_REDEMPTION_CODE_PLACEHOLDER}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.code}
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* PIN */}
                        <Form.Group className="mb-3" controlId="ticketRedemptionTicketPin">
                            <Form.Label className="auth-label">{Strings.TICKET_REDEMPTION_TICKET_PIN}</Form.Label>
                            <Form.Control
                                type="text"
                                required
                                isInvalid={ formError.pin != null}
                                value={form.pin}
                                onChange={e => updateForm('pin', e.target.value)}
                                plaintext={"PIN"}
                                placeholder={Strings.TICKET_REDEMPTION_REDEMPTION_PIN_PLACEHOLDER}
                            />

                            <Form.Control.Feedback type="invalid">
                                {formError.pin}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <TigerButton className="w-100" variant="red" disabled={loading}>
                            {loading ?
                                <Spinner animation="border" />
                                :
                                Strings.TICKET_REDEMPTION_SUBMIT_CODE_PIN}
                        </TigerButton>
                    </Form>

                    {accountId != null &&
                        <p style={{marginTop: "30px", fontSize: "20px", textAlign: "center"}}>
                            <Link to={"/" + GlobalConstants.APP_PATH + "dashboard"}
                                  style={{fontWeight: "bold", color: "#333", textDecoration: "underline"}}>
                                {Strings.TICKET_REDEMPTION_DASHBOARD_LINK}
                            </Link>
                        </p>
                    }
                </div>

            </div>

            <InfoModal
                title={Strings.REGISTER_UNKNOWN_ERROR_TITLE}
                text={errorText}
                show={showError}
                onHide={() => setShowError(false)} />
        </div>
    )

}
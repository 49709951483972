import React, { Component } from "react";
import logo from "../../../assets/images/loader_bg.png";
import ErrorCodes from "../../config/ErrorCodes";
import {BackButton} from "../../global/tiger-button/BackButton";
import {withToast} from "../../util/ToastService";
import "./Series.scss";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import EpisodeItem from "./EpisodeItem";
import {GetRecentlyPublishedEpisodes, GetSeries} from "./SeriesService";
import { Helmet } from "react-helmet";
import {Form, FormSelect, Spinner} from "react-bootstrap";
import {FaGripHorizontal, FaList, FaSearch} from "react-icons/all";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import {GetProfilePreferences, GetProfilesOfAccount, UpdatePreferences} from "../profiles/ProfileService";
import GlobalConstants from "../../config/GlobalConstants";


class SeriesDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            profileId: null,
            seriesId: this.props.match.params.id,
            slugInUrl: this.props.match.params.slug,
            series: null,
            filter: {
                query: ''
            },
            viewType : 'list',
            viewTypeClass : '',
            cluster: null,

            showProfileSelect : false,
            profiles: [],
            isSelected: null,
        };
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.series !== this.props.series) {
            await this.loadSeries();
        }
        if (prevProps.accountId !== this.props.accountId) {
            this.setState(prevState => ({
                ...prevState,
                accountId: this.props.accountId,
            }), () => {
                this.loadSeries();
            });
        }
    }

    async componentDidMount() {
        if (this.props.location.state) {
            let cluster = {};
            cluster.id = this.props.location.state.clusterId;
            this.setState({
                cluster: cluster,
                profileId : this.props.location.state.profileId,
                isSelected: this.props.location.state.isSelected,
                profileLink: this.props.location.state.profileLink
            }, () => this.loadSeries());
        } else {
            await this.loadSeries();
        }
        const anchor = window.location.hash.slice(1);
        if (anchor) {
            const anchorEl = document.getElementById(anchor);
            if (anchorEl) {
                anchorEl.scrollIntoView();
            }
        } else {
            window.scrollTo(0, 0);
        }
    }

    async loadSeries(seriesId) {
        if (this.props.series == null && this.state.seriesId != null) {
            this.setState({
                loading: true,
            });

            const response = await GetSeries(this.state.seriesId);
            if (response.error === true) {
                this.setState(prevState => ({...prevState, error: response}));
            } else {
                let series = response.result;
                let showProfileSelect = false;
                let cluster = {};
                if (series.contentClusterId && this.state.accountId) {
                    showProfileSelect = true;
                    cluster.id = series.contentClusterId;
                }
                this.setState(prevState => ({
                    ...prevState,
                    cluster: cluster,
                    series: series,
                    showProfileSelect: showProfileSelect,
                }), () => {
                    this.loadProfiles(this.state.accountId);
                    if (this.state.series && this.state.series.slug !== this.state.slugInUrl) {
                        window.history.pushState({}, null, '/series/' + this.state.series.slug + "/" + this.state.series.id);
                    }
                })
                GetRecentlyPublishedEpisodes(this.state.seriesId).then((response) => {
                    if (!response.error) {
                        this.setState(prevState => ({
                            ...prevState,
                            recentlyPublished: response.result?._embedded?.episodes,
                        }));
                    }
                });
            }

            this.setState({
                loading: false,
            });
        }
    }

    async loadProfiles(accountId) {
        if (accountId) {
            let profilesResponse = await GetProfilesOfAccount(accountId);

            if (!profilesResponse.error) {
                const profiles = profilesResponse.result._embedded.profiles;

                let cluster = {};
                if (this.state.series?.contentClusterId) {
                    cluster.id = this.state.series.contentClusterId;
                    this.isClusterEnabled(profiles[0].id).then(isSelected => {
                        this.setState(prevState => ({
                            ...prevState,
                            isSelected: isSelected,
                            cluster: cluster,
                            profiles: profiles,
                            profileId: profiles[0].id,
                        }));
                    })
                }
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_USER + " - " + profilesResponse.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState(prevState => ({
                    ...prevState,
                    profiles: []
                }));
            }
        }
    }

    toggleViewType() {
        let newViewType = this.state.viewType === 'list' ? 'grid' : 'list';
        let newViewTypeClass = this.state.viewType === 'list' ? ' compact' : '';
        this.setState(prevState => ({
            ...prevState,
            viewType: newViewType,
            viewTypeClass: newViewTypeClass,
        }));
    }

    async toggleCluster(clusterId) {
        if (this.state.profileId) {
            //Load the clusters
            this.setState(prevState => ({...prevState, updatingPreferences: true}));
            let cluster = this.state.cluster;

            let response = await GetProfilePreferences(this.state.profileId);
            if (!response.error) {
                let clusters = response.result && response.result._embedded && response.result._embedded.clusters;
                if (clusters === undefined) {
                    clusters = [];
                }
                const index = clusters.map(i => Number(i.id)).indexOf(Number(clusterId));
                if (index > -1) {
                    clusters.splice(index, 1);
                } else {
                    clusters.push(cluster);
                }
                UpdatePreferences(this.state.profileId, clusters).then(r => {
                    this.setState(prevState => ({
                        ...prevState,
                        isSelected: !this.state.isSelected,
                        updatingPreferences: false}));
                }).catch(r => this.setState(prevState => ({...prevState, updatingPreferences: false})));
            } else {
                this.setState(prevState => ({...prevState, updatingPreferences: false}));
            }
        }
    }

    handleSelectProfile = (selectedProfile) => {
        this.isClusterEnabled(selectedProfile).then(isSelected => {
            this.setState(prevState => ({
                ...prevState,
                profileId: selectedProfile,
                isSelected: isSelected,
            }));
        })
    };

    async isClusterEnabled(profileId) {
        if (profileId) {
            let response = await GetProfilePreferences(profileId);
            if (!response.error) {
                let clusters = response.result && response.result._embedded && response.result._embedded.clusters;
                if (clusters === undefined) {
                    clusters = [];
                }
                const index = clusters.map(cluster => Number(cluster.id)).indexOf(Number(this.state.series.contentClusterId));
                return index > -1;
            }
        }
    }

    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "profile-details",
            hash: this.state.source,
            state: this.state.profileLink
        });
    }

    render() {
        const series = this.state.series;
        const recentlyPublished = this.state.recentlyPublished;
        let coverUrl;
        let coverClass = '';
        if (series) {
            coverUrl = logo;
            if (series.displayImageUrl) {
                coverUrl = series.displayImageUrl;
            } else if (series.seasons && series.seasons[0] && series.seasons[0].episodes && series.seasons[0].episodes[0]
                    && series.seasons[0].episodes[0].displayImageUrl) {
                coverUrl = series.seasons[0].episodes[0].displayImageUrl;
                coverClass = 'rounded-circle';
            }
        }

        if(this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br />
                    <br />
                    <br />
                    <TigerSpinner />
                    <br />
                </div>
            )
        }

        let options = [];
        if (this.state.showProfileSelect) {
            this.state.profiles.map(profile =>
                options.push({ label: profile.name, value: profile.id }),
            );
        }

        return (
                series && series.seasons && series.seasons.length > 0 && series.seasons[0].episodes.length ?
                <div className="series-details-container">
                    {this.state.profileLink && this.state.cluster &&
                        <BackButton onClick={() => this.closeView()}/>
                    }

                <div className="search-query-input-container">
                    <Form onSubmit={() => {
                        this.props.history.push('/search/?query=' + this.state.filter.query);
                    }}>

                    <Form.Control type="search"
                                  value={this.state.filter.query}
                                  className="search-query-input"
                                  placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                  onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                          ...prevState.filter,
                                          query: e.target.value
                                      }}))} />
                    <button id={"search-button"} type="submit"><FaSearch /></button>
                    </Form>
                </div>

                <div className="series-container">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.series && this.state.series.name ? this.state.series.name + " :: tiger.media" : "Serie :: tiger.media"}</title>
                        <meta name="description" content={'Episoden und Reihenfolge der Folgen der Hörspielserie ' + (this.state.series && this.state.series.name ? this.state.series.name : '')  + " verfügbar bei tigertones und auf der tigerbox"} />
                        <meta name="keywords" content={'Serie,Hörspiel,Episodenguide,Reihenfolge,Kinder,tigerbox,Musik,Serienguide,Folgen,tigertones,streaming,streamen'} />
                        <link rel="canonical" href={"https://my.tiger.media/series/" + this.state.series.slug + "/" + this.state.series.id}/>
                        <meta  property = "og:title"  content={this.state.series && this.state.series.name ? this.state.series.name : "Serieninformationen"} />
                        <meta  property = "og:description"  content={'Episoden und Reihenfolge der Folgen der Hörspielserie ' + (this.state.series && this.state.series.name ? this.state.series.name : '') + " verfügbar bei tigertones und auf der tigerbox"}/>
                        <meta  property = "og:type"  content="website" />
                        <meta  property = "og:image"  content={coverUrl} />
                        <meta  property = "og:image:alt"  content={"Titelbild der Serie " + series.name} />
                        <meta  property = "og:url"  content={"https://my.tiger.media/series/" + this.state.series.slug + "/" + this.state.series.id} />
                    </Helmet>

                    <div className="series-information-container">
                        <div className="series-cover-and-buttons-container">
                            <div className="series-cover">
                                <div className="series-cover-background-container">
                                    {series.ageRecommended &&
                                        <div className="cover-age-display" dangerouslySetInnerHTML={{__html:Strings.SERIES_DETAILS_AGE.replace("{0}", series.ageRecommended)}}/>
                                    }
                                </div>
                                <img src={coverUrl}
                                     alt={"Titelbild der Serie " + series.name} className={coverClass}/>

                            </div>
                            <div className={"cluster-button-container"}>
                                {this.props.accountId && options.length > 0 && this.state.showProfileSelect &&
                                    <>
                                        <p className={"cluster-profile-description"}>Füge jetzt diese Serie als Lieblingsheld zum ausgewählten Profil hinzu</p>
                                        <Form>
                                            <FormSelect className="cluster-profile-select"
                                                        size={"lg"}
                                                    value={this.state.profileId}
                                                    onChange={(e) => this.handleSelectProfile(e.target.value)}>
                                                    placeholder={Strings.CHOSE_PROFILE}
                                            >
                                                {options.map(option => (
                                                        <option value={option.value} key={option.value}>{option.label}</option>
                                                ))}
                                            </FormSelect>
                                            {this.state.isSelected ?
                                                    <TigerButton className="cluster-profile-button" variant="red" type="button" onClick={() => this.toggleCluster(this.state.cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>Held entfernen</>}</TigerButton>
                                                    :
                                                    <TigerButton className="cluster-profile-button" variant="green" type="button" onClick={() => this.toggleCluster(this.state.cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>Held auswählen</>}</TigerButton>
                                            }
                                        </Form>
                                    </>
                                }

                                {this.state.profileId && !this.state.showProfileSelect &&
                                        <>
                                            {this.state.isSelected ?
                                                    <TigerButton variant="red" type="button" onClick={() => this.toggleCluster(this.state.cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>{Strings.PROFILE_CLUSTER_REMOVE}</>}</TigerButton>
                                                    :
                                                    <TigerButton variant="green" type="button" onClick={() => this.toggleCluster(this.state.cluster)}>{this.state.updatingPreferences ? <Spinner animation="border" /> : <>{Strings.PROFILE_CLUSTER_ADD}</>}</TigerButton>
                                            }
                                        </>
                                }
                            </div>
                        </div>
                        <div className="series-text-container">
                            <h1 className="series-name" dangerouslySetInnerHTML={{__html:series.name}}></h1>
                            {series.language && series._embedded?.language?.localisedDisplayNameSeriesLanguage &&
                                    <div className="series-age">
                                        {"Sprache: " + series._embedded.language.localisedDisplayNameSeriesLanguage}
                                    </div>
                            }
                            {series.language && series.language === 'gsw' &&
                                    <div className="series-age">
                                        {"Sprache: Schweizer Deutsch"}
                                    </div>
                            }
                            <div className="series-description" dangerouslySetInnerHTML={{__html:series.description}}></div>

                            {series.divisionType === 'SEASONS' && series.seasons.length > 0 &&
                                <div className="series-season-container">
                                    <p>Staffeln:</p>
                                    <ol>
                                        {series.seasons
                                            .sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                                            .map(currentSeason => (
                                                <li className="series-season-title" key={"season-toc-entry-" + currentSeason.id}>
                                                    <a href={"#season-" + currentSeason.id}>{series.divisionType === 'SEASONS' ? currentSeason.name : 'Folgen'}</a>
                                                </li>))}
                                    </ol>
                                </div>
                            }
                        </div>
                    </div>

                    {recentlyPublished?.length > 0 &&
                        <div key={"recently-published-episodes"} id={"recently-published-episodes"}>
                            <div className={"season-information-container"}>
                                <h2 className="season-title">
                                    {'Aktuelle Veröffentlichungen'}
                                </h2>
                            </div>
                            <hr className={"series-hr"}/>
                            <div className="episode-items-container">
                                {recentlyPublished.map(e => (
                                        <>
                                            {e.visibility && e.visibility === 'VISIBLE' &&
                                                    <div className="episode-item compact" key={"recently-" + e.id}>
                                                        <EpisodeItem episode={e} series={series}/>
                                                    </div>
                                            }
                                        </>
                                ))}
                            </div>
                        </div>
                    }

                    {series.seasons.length > 0 &&
                            <>
                        {series.seasons
                                .sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                                .map(currentSeason => (
                                <div key={currentSeason.id} id={"season-" + currentSeason.id}>
                                    <div className={"season-information-container"} key={"season-info-" + currentSeason.id}>
                                        <h2 className="season-title">
                                            {series.divisionType === 'SEASONS' ? currentSeason.name : 'Folgen'}
                                        </h2>
                                        {series.divisionType === 'SEASONS' &&
                                            <div className="season-description" dangerouslySetInnerHTML={{__html:currentSeason.description}}></div>
                                        }
                                        <div className="season-episode-items-view-type-button" role="button" >
                                            {this.state.viewType === 'list' &&
                                                    <FaGripHorizontal size={'30'} onClick={() => {
                                                        this.toggleViewType();
                                                    }}/>
                                            }
                                            {this.state.viewType === 'grid' &&
                                                    <FaList size={'30'} onClick={() => {
                                                        this.toggleViewType();
                                                    }}/>
                                            }
                                        </div>
                                    </div>
                                    <hr className={"series-hr"}/>
                                    <div className="episode-items-container" key={"episodes-" + currentSeason.id}>
                                        {currentSeason.episodes.map(e => (
                                            <>
                                            {e.visibility && e.visibility === 'VISIBLE' &&

                                                <div className={'episode-item' + this.state.viewTypeClass} key={e.id}>
                                                    <EpisodeItem episode={e} series={series}/>
                                                </div>
                                            }
                                            </>
                                        ))}
                                    </div>
                                </div>
                        ))}
                            </>
                    }
                    </div>
                </div>
                :
                <>
                    <div className="search-query-input-container">

                        <Form.Control type="search" value={this.state.filter.query}
                                      className="search-query-input"
                                      placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                      onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                              ...prevState.filter,
                                              query: e.target.value
                                          }}))} />
                        <button id={"search-button"} onClick={() => {
                            this.history.push("/search/?query=" + this.state.filter.query);
                        }}><FaSearch /></button>
                    </div>

                    <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "25px"}}>
                        <img src={logo} alt="tigermedia logo" width={100} />
                        <br />
                        <br />
                        <span style={{fontSize: "40px"}}>{Strings.SERIES_NO_EPISODES}</span>
                        <br />
                        <br />
                        {Strings.SEARCH_ERROR_MESSAGE}
                    </div>
                </>
        );
    }
}

export default withToast(SeriesDetails);

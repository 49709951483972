import React, { Component } from "react";
import {BackButton} from "../../global/tiger-button/BackButton";
import {
    GetEpisodeNumberText,
    GetProduct, GetPrevNextProductsInSeries,
    GetProductTitleWithoutSeriesInformation
} from "./ProductService";
import logo from "../../../assets/images/loader_bg.png";
import "./ProductDetails.scss";
import {FaChevronDown, FaSearch} from "react-icons/all";
import Strings from "../../config/Strings";
import {TigerSpinner} from "../../global/tiger-spinner/TigerSpinner";
import {TigerButton} from "../../global/tiger-button/TigerButton";
import GlobalConstants from "../../config/GlobalConstants";
import {GetWatchlistEntry,
        AddWatchlistEntry,
        DeleteWatchlistEntry} from "../watchlist/WatchlistService";
import ErrorCodes from "../../config/ErrorCodes";
import {GetProfilesOfAccount} from "../profiles/ProfileService";
import {withToast} from "../../util/ToastService";
import {Helmet} from "react-helmet";
import {Form, FormSelect} from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import radioplay from "../../../assets/images/search/radioplay.png";
import audiobook from "../../../assets/images/search/audiobook.png";
import music from "../../../assets/images/search/music.png";
import {getProductLength} from "../search/SearchService";
import {InfoModal} from "../../global/ConfirmationModal";
import SearchResultItem from "../search/SearchResultItem";
import {isMobile} from "react-device-detect";
import tigertones_app_icon from "../../../assets/images/ddf-tigertones-app-icon.png";

class ProductDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showWatchlistButton: true,
            showEpisodesModal: false,
            allEpisodesText: null,
            prevEpisode: null,
            nextEpisode: null,
            loading: false,
            product: null,
            profileLink: null,
            accountId : null,
            profiles: null,
            selectedProfile: null,
            isOnWatchlist: false,
            productId: this.props.match.params.id,
            slugInUrl: this.props.match.params.slug,
            ddfProductIds: GlobalConstants.DDF_PRODUCT_IDS.split(',').map(i => Number(i)),
            filter: {
                query: ''
            }

        };
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.product !== this.props.product) {
            await this.loadProduct();
        }
        if (prevProps.accountId !== this.props.accountId) {
            this.setState({
                accountId: this.props.accountId ? this.props.accountId : null,
            });
            await this.loadProfiles();
        }
    }

    async componentDidMount() {
        if (this.props.location.state) {
            this.setState({
                clusterId: this.props.location.state.clusterId != null ? this.props.location.state.clusterId : null,
                profileLink: this.props.location.state.profileLink != null ? this.props.location.state.profileLink : null,
                accountId: this.props.location.state.accountId != null ? this.props.location.state.accountId : null,
                selectedProfile: this.props.location.state.selectedProfile != null ? this.props.location.state.selectedProfile : null,
                isOnWatchlist: this.props.location.state.isOnWatchlist != null ? this.props.location.state.isOnWatchlist : false
            }, () => {this.loadProduct(); this.loadProfiles()});
        } else {
            await this.loadProduct();
            if (this.props.accountId) {
                let profilesResponse = await GetProfilesOfAccount(this.props.accountId);

                if (profilesResponse.error === false) {
                    this.setState({
                        accountId: this.props.accountId,
                    }, () => {
                        this.loadProfiles();
                    });
                } else {
                    this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_USER + " - " + profilesResponse.message, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                    this.setState({
                        users: []
                    });
                }
            }

        }
    }

    async loadProfiles() {
        if (this.props.accountId) {
            let profilesResponse = await GetProfilesOfAccount(this.props.accountId);

            if (profilesResponse.error === false) {
                const profiles = profilesResponse.result._embedded.profiles;

                this.setState({
                    profiles: profiles,
                    selectedProfile: { label: profiles[0].name, value: profiles[0].id },
                }, () => {
                    this.setState({isOnWatchlist: this.isProductOnWatchlist(profiles[0].id)});
                });
            } else {
                this.props.addToast(Strings.TOAST_ERROR_FETCHING_DATA + ErrorCodes.CODE_USER + " - " + profilesResponse.message, {
                    autoDismiss: true,
                    appearance: 'error',
                    placement: 'bottom-center'
                });
                this.setState({
                    profiles: []
                });
            }
        }
    }

    async loadProduct() {
        if (!this.props.product && this.state.productId != null) {
            this.setState({
                loading: true,
            });

            GetProduct(this.state.productId).then((response) => {
                let product = response.result._embedded.simpleHalRepresentationModels[0];
                //We only want to show products with state = 'ACTIVE'
                if (product && product.state === 'ACTIVE') {
                    let allEpisodesText = '';
                    if (product._embedded?.series && product._embedded?.episodes?.length > 0) {
                        for (const episode of product._embedded.episodes) {
                            allEpisodesText += episode.episodeTitle + '<br/>';
                        }
                    }

                    let arrowButton = this.GetArrowButtonOfEpisodesModal(product, allEpisodesText);
                    let title = GetProductTitleWithoutSeriesInformation(product, arrowButton);

                    this.setState({
                        product: product,
                        title: title,
                        arrowButton: arrowButton,
                    }, () => {
                            if (this.state.product?._embedded?.slugs?.length > 0 && this.state.product._embedded.slugs[0].defaultValue !== null
                                    && this.state.product._embedded.slugs[0].defaultValue !== this.state.slugInUrl) {
                                window.history.pushState({}, null, '/product/' + this.state.product._embedded.slugs[0].defaultValue + "/" + this.state.product.id);
                            } else if ((this.state.product?._embedded?.slugs?.length === 0 || !this.state.product._embedded.slugs[0].defaultValue) && this.state.slugInUrl) {
                                window.history.pushState({}, null, '/product/' + this.state.product.id);
                            }

                            this.setState({
                                loading: false,
                            });
                        }
                    )
                    this.getPrevNextProductsInSeries(product.id);
                } else {
                    this.setState({
                        product: null
                    });

                    //Window title
                    document.title = "Produkt nicht gefunden";
                    this.setState({
                        loading: false,
                    });
                }
            });
        } else {
            this.setState({
                product: this.state.product,
            });
        }
    }

    async getPrevNextProductsInSeries(productId) {
        if (productId) {
            GetPrevNextProductsInSeries(productId).then(response => {
                if (!response.error && response.result) {
                    this.setState({
                        next: response.result._embedded.next,
                        prev: response.result._embedded.prev,
                    });
                } else {
                    this.setState({
                        next: null,
                        prev: null
                    });
                }
            })
        }
    }


    closeView = () => {
        this.props.history.push({
            pathname: "/" + GlobalConstants.APP_PATH + "cluster/" + this.state.clusterId,
            state: {
                profileLink: this.state.profileLink,
                accountId: this.state.accountId,
                selectedProfile: this.state.selectedProfile,
                isOnWatchlist: this.state.isOnWatchlist,
                productId: this.state.product.id
            }
        });
    }

    handleSelectProfile = (selectedProfile) => {
        this.setState({
            selectedProfile: selectedProfile,
            isOnWatchlist: this.isProductOnWatchlist(selectedProfile.value)}
        );
    };

    async isProductOnWatchlist(profileId) {
        if (profileId) {
            GetWatchlistEntry(this.state.accountId, profileId, this.state.productId).then(response => {
                if (!response.error && response.result) {
                    this.setState({
                        isOnWatchlist: true
                    });
                } else {
                    this.setState({
                        isOnWatchlist: false
                    });
                }
            })
        }
    }

    async addToWatchlist() {
        console.log("adding to watchlist")
        if (this.state.selectedProfile) {
            AddWatchlistEntry(this.state.accountId, this.state.selectedProfile.value, this.state.productId).then(response => {
                if (!response.error) {
                    this.props.addToast(Strings.WATCHLIST_ADD_SUCCESS, {
                        autoDismiss: true,
                        appearance: 'success',
                        placement: 'bottom-center'
                    });

                    this.setState({
                        isOnWatchlist: true
                    });
                }
                else {
                    this.props.addToast(Strings.WATCHLIST_ADD_ERROR + response.message, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                    this.setState({
                        isOnWatchlist: false
                    });
                }
            });

        }
    }

    async removeFromWatchlist() {
        if (this.state.product) {

            DeleteWatchlistEntry(this.state.accountId, this.state.selectedProfile.value, this.state.productId).then(response => {
                if (!response.error) {
                    this.props.addToast(Strings.WATCHLIST_REMOVE_SUCCESS, {
                        autoDismiss: true,
                        appearance: 'success',
                        placement: 'bottom-center'
                    });

                    this.setState({
                        isOnWatchlist: false
                    });
                }
                else {
                    this.props.addToast(Strings.WATCHLIST_REMOVE_ERROR + response.message, {
                        autoDismiss: true,
                        appearance: 'error',
                        placement: 'bottom-center'
                    });
                    this.setState({
                        isOnWatchlist: true
                    });
                }
            });
        }
    }

    GetArrowButtonOfEpisodesModal(product, allEpisodesText) {
        if (product?._embedded?.series && product._embedded.series[0])
        {
            const episodes = product._embedded.episodes;
            if (episodes.length > 1) {
                return <OverlayTrigger
                        trigger="click"
                        key={'all-episodes-popover-trigger'}
                        placement={'bottom'}
                        rootClose={true}
                        overlay={
                            <Popover id={'episode-popover'}>
                                <Popover.Header as="h3">{'Alle enthaltenen Episoden'}</Popover.Header>
                                <Popover.Body dangerouslySetInnerHTML={{__html:allEpisodesText}}>
                                </Popover.Body>
                            </Popover>
                        }>
                           <div role="button">
                                <FaChevronDown/>
                           </div>
                        </OverlayTrigger>;
            }
        }
        return null;

    }


    render() {
        const product = this.state.product;
        let episodeNumberText;
        let series;
        let seriesDisplayImage;
        let season;
        if (product && product._embedded && product._embedded.series && product._embedded.series[0]) {
            series = product._embedded.series[0];
            seriesDisplayImage = series?.displayImageUrl;
            if (product._embedded.season) {
                season = product._embedded.season[0];
            }
            const episodes = product._embedded.episodes;
            episodeNumberText = series.seriesType ==='ARTIST'? '' : GetEpisodeNumberText(episodes[0]);
        }

        let options = [];
        if (this.state.profiles) {
            this.state.profiles.map(profile =>
                options.push({ label: profile.name, value: profile.id }),
            );
        }

        if (this.state.loading) {
            return (
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "20px"}}>
                    <br/>
                    <br/>
                    <br/>
                    <TigerSpinner/>
                    <br/>
                </div>
            )
        }

        let keywords;
        let coverUrl;
        let productLength;
        if (product) {
            coverUrl = product.cover ? product.cover.contentLocation.location : logo;
            keywords = product.keywords ? product.keywords.join(',') : '';
            const minutes = getProductLength(product);
            if (minutes) {
                productLength = 'Laufzeit:<br/>' + minutes;
            }
        }

        return (
            product ?
                <div className="product-details-container">
                    {this.state.profileLink && this.state.clusterId &&
                        <BackButton onClick={() => this.closeView()}/>
                    }

                    <div className="search-query-input-container">
                        <Form onSubmit={() => {
                            this.props.history.push('/search/?query=' + this.state.filter.query);
                        }}>

                            <Form.Control type="search"
                                          value={this.state.filter.query}
                                          className="search-query-input"
                                          placeholder={Strings.PROFILE_PREFERENCES_SEARCH}
                                          onChange={(e) => this.setState(prevState => ({...prevState, filter: {
                                                  ...prevState.filter,
                                                  query: e.target.value
                                              }}))} />
                            <button id={"search-button"} type="submit" aria-label="Suchen"><FaSearch /></button>
                        </Form>
                    </div>

                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{product?.title ? product.title + " :: tiger.media" : "Audio-Titel :: tiger.media"}</title>
                        <meta name="description" content={product.description.substring(0,200)} />
                        <meta name="keywords" content={'tigerbox,tigertones,Hörspiel,Hörbuch,Kindermusik,Musik für Kinder,streaming,streamen,' + keywords} />
                        <link rel="canonical" href={"https://my.tiger.media/product/" + this.state.product._embedded.slugs[0].defaultValue + "/" + this.state.product.id}/>
                        <meta  property = "og:title"  content={product?.title ? product.title : "Informationen zu Audio-Titel"} />
                        <meta  property = "og:description"  content={product.description}/>
                        <meta  property = "og:type"  content="website" />
                        <meta  property = "og:image"  content={coverUrl} />
                        <meta  property = "og:image:alt"  content={"Titelbild von " + product.title} />
                        <meta  property = "og:url"  content={"https://my.tiger.media/product/" + this.state.product._embedded.slugs[0].defaultValue + "/" + this.state.product.id} />
                    </Helmet>


                    <div className="product-container">
                        <div className="product-cover">
                            <div className="product-cover-background-container">
                                {product.ageMin &&
                                        <div className="cover-age-display" dangerouslySetInnerHTML={{__html:Strings.SERIES_DETAILS_AGE.replace("{0}", product.ageMin)}}/>
                                }
                                {product.productClassification === 'RADIOPLAY' &&
                                        <div className="cover-product-classification-display" >
                                        Hörspiel<br/>
                                        <img src={radioplay} alt={"radioplay"} />
                                        </div>
                                }
                                {product.productClassification === 'AUDIOBOOK' &&
                                        <div className="cover-product-classification-display" >
                                        Hörbuch<br/>
                                        <img src={audiobook} alt={"audiobook"} />
                                        </div>
                                }
                                {product.productClassification === 'MUSIC' &&
                                        <div className="cover-product-classification-display" >
                                        Musik<br/>
                                        <img src={music} alt={"music"} />
                                        </div>
                                }
                                <div className="cover-product-length-display" dangerouslySetInnerHTML={{__html: productLength}}/>
                            </div>
                            <img src={coverUrl}
                                 alt={"Titelbild des Hörspiels " + product.title}/>

                        </div>

                        <div className="product-text-container">
                            <div className="product-basic-info-series-display-image-container">
                                <div className="product-basic-info-container">
                                    {series && episodeNumberText &&
                                            <div className="episode-number" dangerouslySetInnerHTML={{__html: episodeNumberText + ':'}}/>
                                    }

                                    <h1 className="title">{this.state.title}</h1>
                                    {series &&
                                            <div className="product-attributes"
                                                 dangerouslySetInnerHTML={{__html: 'aus <a class="series-link" href="/series/' + series.slug + '/' + series.id + '">' + series.name + '</a>' +
                                                             (series.divisionType === 'SEASONS' && season ? ' > <a class="series-link" href="/series/' + series.slug + '/' + series.id + '#season-' + season.id + '">' + season.name + '</a>' : '')
                                                             }}/>
                                    }


                                    <div className="product-attributes">
                                        {product.author &&
                                            <span>Autor: {product.author}<br/></span>
                                        }
                                        {product.attributes.illustrator &&
                                                <span>Sprecher: {product.attributes.illustrator}<br/></span>
                                        }
                                        {product.imprint && product.imprint.name &&
                                                <span>Label: {product.imprint.name}<br/></span>
                                        }
                                        {product._embedded?.language?.localisedDisplayNameProductLanguage &&
                                            "Sprache: " + product._embedded.language.localisedDisplayNameProductLanguage
                                        }
                                    </div>
                                </div>
                                {seriesDisplayImage &&
                                    <a className={"product-series-display-image"} href={"/series/" + series.slug + "/" + series.id}>
                                        <img src={seriesDisplayImage} alt={"Titelbild der Serie " + series.name}/>
                                    </a>
                                }
                            </div>
                            <div className="product-description" dangerouslySetInnerHTML={{__html: product.description}}/>
                        </div>
                        <div className="watchlist-container">
                            {this.props.accountId && this.state.showWatchlistButton &&
                                <div className="dashboard-card-title watchlist-headline">{Strings.WATCHLIST_ADD_HEADLINE}</div>
                            }

                            {this.props.accountId && options.length > 0 && this.state.showWatchlistButton &&
                                    <div>
                                        <FormSelect className="watchlist-profile-select"
                                                    value={this.state.selectedProfile}
                                                    onChange={(e) => this.handleSelectProfile(e.target.value)}>
                                            placeholder={Strings.CHOSE_PROFILE}
                                            >
                                            {options.map(option => (
                                                    <option value={option.value} key={option.value}>{option.label}</option>
                                            ))}
                                        </FormSelect>
                                    </div>
                            }
                            {this.props.accountId && this.state.showWatchlistButton &&
                                    <div className="watchlist-button-box">
                                        {this.state.isOnWatchlist ?
                                                <TigerButton className="watchlist-button" variant="red" type="button" onClick={() => this.removeFromWatchlist()}>{<>{Strings.WATCHLIST_REMOVE_BUTTON_EXTENDED_LABEL}</>}</TigerButton>
                                                :
                                                <TigerButton disabled={!this.state.selectedProfile || this.state.selectedProfile === ''} className="watchlist-button"
                                                             variant="green" type="button"
                                                             onClick={() => this.addToWatchlist()}>{<>{Strings.WATCHLIST_ADD_BUTTON_LABEL}</>}</TigerButton>
                                        }
                                    </div>
                            }

                            {!this.state.ddfProductIds.includes(product.id) && (!this.props.accountId || !this.state.showWatchlistButton) &&
                                    <div className="dashboard-card-title watchlist-headline" dangerouslySetInnerHTML={{__html:Strings.WATCHLIST_NO_LOGIN_HINT.replace('{0}', product.id)}}>

                                    </div>
                            }
                        </div>
                    </div>

                    <div className={'prev-next-products-in-series-container'}>
                            <div className={'prev-product-container'}>
                                {this.state.prev &&
                                     <>
                                        <div className={'prev-next-text'}>Vorherige Folge:</div>
                                        <SearchResultItem product={this.state.prev} prevNextItem={true}/>
                                     </>
                                }

                            </div>
                        <div className={'product-start-tigertones'}>
                            {isMobile &&
                                    <div className="cluster-product-button">
                                        <a href={GlobalConstants.TONES_DEEPLINK_TO_PRODUCT + product.id}>
                                            <img src={tigertones_app_icon} alt={"tigertones logo"}/>
                                        </a>
                                        <div className={'prev-next-text'}>In tigertones wiedergeben</div>
                                    </div>
                            }
                        </div>
                            <div className={'next-product-container'}>
                            {this.state.next &&
                                    <>
                                        <div className={'prev-next-text'}>Nächste Folge:</div>
                                        <SearchResultItem product={this.state.next} prevNextItem={true}/>
                                    </>
                            }
                            </div>
                    </div>

                    {!this.state.ddfProductIds.includes(product.id) && (!this.props.accountId || !this.state.showWatchlistButton) &&
                        <div className="product-cta">
                            <div dangerouslySetInnerHTML={{__html:Strings.PRODUCT_DETAILS_CTA}} />
                            <br/>
                            <br/>
                            <div className="product-cta-button-box">
                                <a href={GlobalConstants.LANDING_PAGE_TIGERTICKET} className="product-cta-button-left">
                                    <TigerButton className="w-100" variant="red"
                                                 type="button">{Strings.PRODUCT_DETAILS_CTA_TICKET}</TigerButton>
                                </a>
                            </div>
                        </div>
                    }

                    <InfoModal
                            title={"Epsioden"}
                            text={this.state.allEpisodesText}
                            show={this.state.showEpisodesModal}
                            onHide={() => this.setState(prevState => ({...prevState, showEpisodesModal: false}))} />

                </div>
                :
                <div style={{width: "100%", height: "500px", textAlign: "center", fontSize: "25px"}}>
                    <br/>
                    <img src={logo} alt="tigermedia logo" width={100}/>
                    <br/>
                    <br/>
                    <span style={{fontSize: "40px"}}>{Strings.PRODUCT_DETAILS_ERROR_TITLE}</span>
                    <br/>
                    <br/>
                    {Strings.PRODUCT_DETAILS_ERROR_MESSAGE} <a
                    href={"https://tiger.media/"}>{Strings.PRODUCT_DETAILS_ERROR_LINK}</a>
                </div>
        );
    }
}

export default withToast(ProductDetails);
